<template>
</template>
<script>
export default {
    beforeCreate(){
        parent?.window?.korpayComplete?.(this.$route.query);
        opener?.window?.korpayComplete?.(this.$route.query);
        if(parent || opener) window.close();
    },
}
</script>